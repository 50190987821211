<template>
    <section class="appoint">
        <div class="container">
            <div class="row" v-for="(item,index) in whychooseus" :key="index">
                <div class="col xs-12 sm-7 md-8">
                    <div class="appoint__img">
                        <img :src="urlPath+'uploads/media/page/'+item.image" alt="image description">
                    </div>
                </div>
                <div class="col xs-12 sm-5 md-4">
                    <div class="appoint__about">
                        <div class="headline appoint__about-title">
                            <h2 class="headline__title">Why <span>Choose Us</span></h2>
                        </div>
                        <div class="chooseContent" v-html="item.description"></div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios';
export default ({
    name:'Chooseus',
    data(){
        return{
            whychooseus:undefined,
            apiPath:process.env.VUE_APP_API,
            urlPath:process.env.VUE_APP_PATH,
        }
    },
    mounted(){
        this.chooseus();
    },
    methods:{
        chooseus(){
            axios.get(this.apiPath+'showpage/why-choose-us')
            .then((response)=>{
                this.whychooseus=response.data;
            })
        }
    }

})
</script>